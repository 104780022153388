import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogin: !!localStorage.getItem('token'),
    memberInfo: localStorage.getItem('memberInfo') ? JSON.parse(localStorage.getItem('memberInfo')) : {},
    tags: localStorage.getItem('tags') ? JSON.parse(localStorage.getItem('tags')) : [],
	isMockLogin:'',
    robotId: localStorage.getItem('robotId') ? localStorage.getItem('robotId') : '',
    robotName: localStorage.getItem('robotName') ? localStorage.getItem('robotName') : '',
  },
  mutations: {
    setLogin(state,isLogin){
      state.isLogin = isLogin
    },
    setMemberInfo(state, info) {
      state.memberInfo = info
    },
    setTags(state, tags) {
      state.tags = tags
      localStorage.setItem('tags', JSON.stringify(state.tags))
    },
    pushTags(state, tag) {
      if(!state.tags.includes(tag)){
        state.tags.unshift(tag)
      }
      localStorage.setItem('tags', JSON.stringify(state.tags))
    },
    deleteTags(state, index){
      state.tags.splice(index,1)
      localStorage.setItem('tags', JSON.stringify(state.tags))
    },
    setIsMockLogin(state, val){
      state.IsMockLogin=val
    },
    setRobotId(state, id) {
      state.robotId = id
    },
    setRobotName(state, name) {
      state.robotName = name
    }
  },
  actions: {

  },
  getters:{
    isLogin: state => state.isLogin,
    memberInfo: state => state.memberInfo,
	tags: state => state.tags,
    robotId: state => state.robotId,
    robotName: state => state.robotName,
  },
  modules: {
  }
})

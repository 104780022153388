import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "../utils/axios";

const chat = () => import('../views/alRobot/chat/chat');

const share = () => import('../views/alRobot/chat/share');
const page = () => import('../views/alRobot/chat/page');

Vue.use(VueRouter);

const routes = [
        {
            path: '',
            redirect: '/alRobot/chat/page',
            component: page,
            meta: {
                auth: false,
                title: '聊天'
            }
        },
        {
            path: '/alRobot/chat/chat',
            component: chat,
            meta: {
                auth: false,
                title: '聊天'
            }
        },
    {
        path: '/alRobot/chat/share',
        component: share,
        meta: {
            auth: false,
            title: '聊天'
        }
    },
        {
            path: '/alRobot/chat/page',
            component: page,
            meta: {
                auth: false,
                title: '测试聊天'
            }
        },

];

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// 路由拦截器
router.beforeEach(async (to, from, next) => {
    let promotion_id = to.query.ref
    if(promotion_id){
        localStorage.setItem('promotion_id', promotion_id.toString())
    }
    redirectToMiniProgram();
    if(to.meta.title){
        document.title = to.meta.title
    }
    if (to.matched.some(record => record.meta.auth) && to.meta.auth) { // 判断该路由是否需要登录权限
        let token = localStorage.getItem('token');
        if (token) { // 获取当前的 token 是否存在
            next()
        } else {
            // 不存在 token，需要重新认证
            next({
                path: '/login',
                query: {
                    back_url: encodeURIComponent(to.fullPath)
                }
            })
        }
    }
    next();
});


// 判断是否在移动设备上
function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

// 跳转到微信小程序
function redirectToMiniProgram() {
    // 在移动设备上，使用微信小程序的跳转逻辑
    if (isMobileDevice()) {
        let promotion_id = localStorage.getItem('promotion_id')
        let data = {};
        if(promotion_id){
            data.promotion_id = promotion_id
        }
        // 通过修改以下参数来指定要跳转的微信小程序
        axios.post('/api/common/getUrlScheme',data).then(
            res => {
                location.href = res.result.openlink
            }
        )
    } else {
        // console.log('非移动设备，无需跳转到微信小程序');
    }
}

// 调用跳转函数




export default router

import axios from 'axios'
import router from '../router'
import config from "../config/index";
import { Message } from 'element-ui';

axios.defaults.withCredentials = true;  // 设置cross跨域 并设置访问权限 允许跨域携带cookie信息

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'


const instance = axios.create({
    baseURL: config.baseurl,
    timeout: 10000,
});



// http request 拦截器
instance.interceptors.request.use(
    config => {
        let token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = token
        }
        return config;
    }
);

// http response 拦截器
instance.interceptors.response.use(
    response => {
        // console.log(response.headers)
        // console.log(response.headers['authorization'])
        if(response.headers['authorization']){
            localStorage.setItem('token',response.headers['authorization']);
        }
        // 未登录或会话已过期
        if (-1 === response.data.code ) {
            // 重定向到登录页
            router.push({
                path: '/login',
                query: {redirect: router.currentRoute.fullPath}
            })
        }
        if (0 === response.data.code) {
            // 重定向到登录页
            Message.error({
                message: response.data.msg
            })
        }
        return response.data;


    },
    error => {
        if (500 === error.response.status) {
            // 服务端异常
        }
        return Promise.reject(error) // 返回接口返回的错误信息
    }
);




export default instance

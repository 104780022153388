import Vue from 'vue'
import axios from './utils/axios'
import App from './App.vue'
import router from './router'
import config from "./config/index";
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'swiper/css/swiper.css';


Vue.use(ElementUI);
Vue.use(NProgress);
Vue.prototype.axios = axios;
Vue.prototype.baseURL = config.baseurl + '/';

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

NProgress.configure({
    minimum: 0.1,
    easing: 'ease',
    speed: 800,
    showSpinner: false
});

<template>
  <div id="app">
    <div class="Content">
      <router-view/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "App",
    components: {
      
    },
    data(){
      return {
       
      }
    },
    mounted() {
      
    },
    watch: {
      
    }
  }
</script>

<style>
  @import "assets/css/base.css";
</style>
